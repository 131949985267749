<template>
  <div>
    <!-- 中文 -->
    <div v-if="$store.state.show">
      <h2>Tools使用文档</h2>
      <p>本视频是关于贴纸配置的介绍</p>
      <video
        ref="videoPlayer"
        controls
        src="@/assets/developer/贴纸配置.mp4"
        style="width: 540px; height: 280px"
      ></video>
      <p>本视频是关于互动贴纸配置的介绍</p>
      <video
        ref="videoPlayer"
        controls
        src="@/assets/developer/互动贴纸.mp4"
        style="width: 540px; height: 280px"
      ></video>
      <h3>1.准备好一套png格式序列图贴纸文件，如下图</h3>
      <img width="460" src="@/assets/developer/tools1.png" alt="互动贴纸配置" />
      <h3>
        2.打开贴纸配置工具，从每组贴纸中各选择一张png进行上传，点击上传，如下图
      </h3>
      <img width="229" src="@/assets/developer/tools2.png" alt="互动贴纸配置" />
      <p>该套贴纸中的五组上传完成如下图。点击每张贴纸右上角红叉可删除</p>
      <img width="530" src="@/assets/developer/tools3.png" alt="互动贴纸配置" />
      <h3>3.选中一张贴纸并用鼠标进行拖动和缩放</h3>
      <img width="530" src="@/assets/developer/tools4.png" alt="互动贴纸配置" />
      <p>
        选中后如上图所示，当上传的贴纸上出现蓝条时表示已选中，贴纸初始位置在脸中间
      </p>
      <p>
        将鼠标放在模特脸上的贴纸可进拖动和缩放，调整大小和位置。
        若是全屏贴纸，则勾选全屏贴纸选项。 效果如下图。
      </p>
      <img width="263" src="@/assets/developer/tools5.png" alt="互动贴纸配置" />
      <h3>4.填入必要参数，点击保存</h3>
      <img width="530" src="@/assets/developer/tools6.png" alt="互动贴纸配置" />
      <p>贴纸所在件夹名，即选中贴纸的父目录名，如第一张图的F_aixin。</p>
      <p>
        贴纸序列图张数，即PNG序列图一共有多少张，填入数字。如F_aixin共有35张
      </p>
      <p>填入完成后点击按钮【确认完成贴纸配置】。</p>
      <h3>5.为其它贴纸进行相同的配置操作</h3>
      <img width="530" src="@/assets/developer/tools7.png" alt="互动贴纸配置" />
      <p>上图为一套贴纸配置完成的截图示。</p>
      <p class="text">
        如果想修改某张贴纸的任何配置(包括文件名、序列图张数、位置或大小)，请点击上传区域中的某个贴纸，并重复
        3，4步的操作(配置完成后务必请点击【确认完成贴纸配置】)。
      </p>
      <h3>6.配置完成，生成配置文件</h3>
      <p class="text">
        点击网页中下方绿色【完成所有配置，
        生成并下载贴纸配置文件】按钮会自动下载配置文件。
      </p>
      <h3>7.将配置文件放在原贴纸文件夹中</h3>
      <img width="257" src="@/assets/developer/tools8.png" alt="互动贴纸配置" />
      <h3>8.点击继续新的贴纸配置，重复1-7步即可配置新的一套贴纸</h3>
    </div>
    <!-- 英文 -->
    <div v-else>
      <h2>Tools usage document</h2>
      <p>This video is an introduction to sticker configuration</p>
      <video
        ref="videoPlayer"
        controls
        src="@/assets/developer/贴纸配置.mp4"
        style="width: 540px; height: 280px"
      ></video>
      <p>This video is an introduction to interactive sticker configuration</p>
      <video
        ref="videoPlayer"
        controls
        src="@/assets/developer/互动贴纸.mp4"
        style="width: 540px; height: 280px"
      ></video>
      <h3>1.Prepare a set of sticker sequence diagram with .png format</h3>
      <img width="460" src="@/assets/developer/tools1.png" alt="互动贴纸配置" />
      <h3>
        2.Open sticker configuration tool, select one PNG from each group of
        stickers to upload，Click on Upload button.
      </h3>
      <img width="229" src="@/assets/developer/tools2.png" alt="互动贴纸配置" />
      <p>
        The five groups in this set of stickers have been uploaded as shown
        below. Click the Red Cross at the top right corner of each sticker to
        delete it.
      </p>
      <img width="530" src="@/assets/developer/tools3.png" alt="互动贴纸配置" />
      <h3>3.Select a sticker, drag and zoom with the mouse.</h3>
      <img width="530" src="@/assets/developer/tools4.png" alt="互动贴纸配置" />
      <p>
        When selected, as shown in the picture above, when the blue bar appears
        on the uploaded sticker, it means that it has been selected. The initial
        position of the sticker is in the middle of the face.
      </p>
      <p>
        Put the mouse on the face of the model sticker can be dragged and
        zooming, resize and position. If full screen sticker, check full screen
        sticker option. The effect is shown below.
      </p>
      <img width="263" src="@/assets/developer/tools5.png" alt="互动贴纸配置" />
      <h3>4.Fill in the necessary parameters and click save.</h3>
      <img width="530" src="@/assets/developer/tools6.png" alt="互动贴纸配置" />
      <p>
        The folder name of the sticker, which is the parent directory name of
        the selected sticker, such as F_aixin of the first picture.
      </p>
      <p>
        The number of sticker sequence diagrams, namely, how many PNG sequence
        diagrams are there? Fill in the number. There are 35 in F_aixin.
      </p>
      <p>
        After filling in, click the button [Complete the configuration of this
        sticker].
      </p>
      <h3>5. Do the same configuration for the other stickers.</h3>
      <img width="530" src="@/assets/developer/tools7.png" alt="互动贴纸配置" />
      <p>The above picture shows a set of stickers configured.</p>
      <p class="text">
        If you want to modify any configuration of a sticker (including file
        name, number of sequence diagrams, location or size), click on a sticker
        in the upload area and repeat step 3 or 4 (be sure to click [c Complete
        the configuration of this sticker] after configuration is completed).
      </p>
      <h3>
        6.The configuration is completed and the configuration file is
        generated.
      </h3>
      <p class="text">
        Click the green button at the bottom of the page [Complete all
        configuration, generate and download the sticker configuration file] to
        download the configuration file automatically.
      </p>
      <h3>7.Place the configuration files in the original sticker folder.</h3>
      <img width="257" src="@/assets/developer/tools8.png" alt="互动贴纸配置" />
      <h3>
        8.Click on the button - [Continue to configure a new set of stickers]
        and repeat steps 1-7 to configure a new set of stickers.
      </h3>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
h2 {
  font-size: 20px;
  font-weight: 500;
  color: #3c4858;
}
h3 {
  font-size: 18px;
  font-weight: 400;
  color: #3c4858;
  margin: 20px 0;
}
p {
  width: 763px;
  margin: 20px 0;
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  color: #3c4858;
}
img {
  margin: 20px 60px;
}
</style>